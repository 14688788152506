import React from "react";
import { Container, Row } from "react-bootstrap";


export default function UserBar(){
    const user = JSON.parse(localStorage.getItem('token'));
    const username = user.username;
    return(
        <Container fluid className="py-1 shadow-lg username-cont" >
            <Row>
                <col-12>
                    <h1 className="h4"><strong>{username}</strong></h1>
                </col-12>
            </Row>
        </Container>
    )
}