import React, {useEffect, useState} from "react";
import authHeader from '../Authentication/authHeader';
import {Button, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {FaPlus} from "react-icons/fa";
import {Accordion} from "react-bootstrap";
import StudentForm from "./StudentForm";
import SingleUser from "./SingleUser";
import AddUser from "./AddUser";

///api/test/admin

const getPersonel = async () => {
    const header = new Headers(authHeader());
    header.set('Accept', 'application/json');
    header.set("Connection", "close");
    return fetch(process.env.REACT_APP_SERVER + '/api/admin/get_all_personel', {
        method: 'GET',
        headers: header,
    });
}

function Personel() {
    const [reload, setReload] = useState(0)
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [add, setAdd] = useState(false);

    useEffect(() => {
        setAdd(false)
        getPersonel().then((response) => {
            return response.json();
        }).then((data) => {
            if (data) {
                //console.log(data.users);
                setUsers(data);
                setLoading(false);
                console.log(data)
            }
        })
            .catch((err) => {
                console.log(err)
            });

    }, [reload])


    return (
        <>
            {
                loading ? <h1>Loading...</h1> : (
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-8 text-start">
                                <h2>Personel: </h2>
                            </div>
                            <div className="col-4 text-end">
                                <Button variant="success" onClick={() => {
                                    setAdd(true)
                                }}>Add &nbsp; <FaPlus/></Button>
                            </div>
                        </div>
                        <Row>
                            <Accordion className={"px-0"} defaultActiveKey={['0']} alwaysOpen>
                                {
                                    add ? <Accordion.Item key={'0'} eventKey={'0'}>
                                        <Accordion.Header>Add User
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <AddUser reload={[reload, setReload]}/>
                                            <div className={'row'}>
                                                <div className={'col-12 text-end'}>
                                                    <Button className={'ms-5'} variant={'outline-danger'}
                                                            onClick={() => {
                                                                setAdd(false)
                                                            }}>Cancel</Button>
                                                </div>
                                            </div>

                                        </Accordion.Body>
                                    </Accordion.Item> : <></>
                                }
                                {users.map((user) => {
                                    return (<Accordion.Item key={user.id} eventKey={user.id}>
                                        <Accordion.Header>{user.username}</Accordion.Header>
                                        <Accordion.Body>
                                            <SingleUser user={user} reload={[reload, setReload]}/>
                                        </Accordion.Body>
                                    </Accordion.Item>)
                                })}

                            </Accordion></Row>
                    </div>)
            }

        </>
    )


}

export default Personel