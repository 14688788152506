import {Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {FaSignOutAlt} from 'react-icons/fa';
import {logOut} from './Authentication/authenticationHandler';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';


function Menu() {
    const roles = JSON.parse(localStorage.getItem('token')).roles;
    const [admin, setAdmin] = useState(false)
    const [active, setActive] = useState('default');
    const [menuOpen, setMenuOpen] = useState(false)
    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const handleClose = () => setMenuOpen(false)


    useEffect(() => {
        roles.map((role) => {
            if (role === "ROLE_ADMIN") {
                setAdmin(true);
            }
            return true;
        })
    }, [])

    return (
        <>
            <Navbar key={'xxl'} expand={false} className="" variant='dark' bg='dark'>
                <Container fluid>
                    <Navbar.Brand href="/">ΖΟΥΖΟΥΝΟΣΧΟΛΕΙΟ</Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} className='btn-menu'
                                   onClick={toggleMenu}/>
                    <Navbar.Offcanvas
                        collapseOnSelect
                        id={`offcanvasNavbar-expand-xxl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
                        placement="start"
                        className="text-center text-bg-dark"
                        restoreFocus={false}
                        show={menuOpen}
                        onHide={handleClose}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xxl`}>
                                ZOYZOYNOSHOLIO O.E.
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="px-0"
                                 activeKey={active}
                                 onSelect={(selectedKey) => setActive(selectedKey)}>
                                <Nav.Link as={Link} to="/" eventKey="default" onClick={toggleMenu}>Τοποθεσία Σχολικού</Nav.Link>
                                    {admin ? <Nav.Link as={Link} to="/admin" eventKey="admin" onClick={toggleMenu}>Admin
                                        Dash</Nav.Link> : <></>}

                            </Nav>
                            <Row className='offcanvas-footer'>
                                <col-12>
                                    <Button variant='light' onClick={() => {
                                        logOut();

                                    }}>Logout &nbsp;<FaSignOutAlt/> </Button>
                                </col-12>
                            </Row>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    );
}

export default Menu;