async function loginUser(credentials,setError,setUser) {
    return fetch(process.env.REACT_APP_SERVER + '/api/auth/signin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
    .then((response) => {
      // The response is a Response instance.
      // You parse the data into a useable format using `.json()`
      return response.json();
    }).then((data) => {
      if(data.accessToken){
        setUser(data);
          }
          else{
            setError(data.message);
          }
    });
   }

function logOut(){
    localStorage.removeItem("token");
    window.location.reload(false);
}

module.exports = {loginUser, logOut}