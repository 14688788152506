import React, { useEffect, useState } from "react";
import authHeader from '../Authentication/authHeader';
import Form from 'react-bootstrap/Form';
import MapView from "./MapView";

///api/test/admin

const getLocations = async  () => {
    const header = new Headers(authHeader());
          header.set('Accept', 'application/json');
    return fetch(process.env.REACT_APP_SERVER + '/api/func/get_locs', {
      method: 'GET',
      headers: header,
        });
   }

   //
   // async function getCurrentLocation() {
   //  const header = new Headers(authHeader());
   //        header.set('Accept', 'application/json');
   //        header.set('Content-Type', 'application/json');
   //  fetch('http://localhost:9000/api/func/get_current_location', {
   //    method: 'POST',
   //    headers: header,
   //    body : JSON.stringify({'location':'location1'})
   //      });
   // }

function BusMonitoring(){
    const [locations,setLocations]= useState([])
    const [loading, setLoading] = useState(true)
    useEffect(()=>{

        getLocations().then((response) => {
            console.log(response);
            return response.json();

      }).then((data)=>{
        if (data){
          console.log(data);
          setLocations(data.locations);
          setLoading(false);
        }
    });

    },[])

    const[location,setLocation] = useState(0)
    const chooseMap = (e) =>{
        setLocation(parseInt(e.target.value))

    }



    return(
        <>
        {
            loading ? <h1>Loading...</h1> : <div>
                    <Form.Select type="dark" aria-label="Default select example" onChange={(e)=>{chooseMap(e)}}>
                    <option key={0} value={0}>Δρομολόγια...</option>
                    {locations.map((location)=>{
                            return(<option key={location.id} value={location.id}>{'Δρομολόγιο ' + location.id}</option>)
                        })}
                    </Form.Select>
                {
                    location === 0 ? <div className={"mt-4"}>Επέλεξε τοποθεσία για να δεις το λεωφορείο στον χάρτη</div> : <MapView location={location}/>
                }

                </div>
        }
        
        </>
    )
    


}
export default BusMonitoring