import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/esm/Container";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import { Alert } from "react-bootstrap";
import { loginUser } from "./Authentication/authenticationHandler";


function Login({ setUser }){
  const [error,setError] = useState();

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const handleSubmit = async e => {
        e.preventDefault();
        
        await loginUser({
          username,
          password
        },setError,setUser);
        //setToken(token);

      }
    return(
        <>
            <Container className="text-center">
                <Row>
                    <col-12>
                    <Card className="p-5 mx-2 my-5 shadow">
                        <h1>Login Page</h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mt-4 mb-3" controlId="formBasicEmail">
                                <Form.Label>Username:</Form.Label>
                                <Form.Control type="text" placeholder="Enter username" onChange={e => setUserName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                            </Form.Group>
                            <Button variant="dark" type="submit">
                                Log In
                            </Button>
                        </Form>
                        {error ? <Alert className="mt-2" variant="danger" >{error}</Alert>: <></>}
                        
                    </Card>
                    </col-12>
                </Row>
            </Container>
            </>
    );
}

export default Login