import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {Button, Container, Row} from "react-bootstrap";
import {FaTrash} from "react-icons/fa";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import authHeader from "../Authentication/authHeader"; // Import css




function StudentForm(props){

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [prwi,setPrwi] = useState("");
    const [mesimeri,setMesimeri] = useState("");
    const [altered ,setAltered] =useState(false);

    useEffect(() => {
        const user = props.user;
        if (user) {
            setName(user.name);
            setEmail(user.email);
            setPhone(user.phone);
            setPrwi(user.prwi);
            setMesimeri(user.mesimeri);
        }
    }, []);



    const handleSubmit = (e) =>{
        e.preventDefault();

        if (props.user){
            //TODO: POST data to be updated on Firebase
            const data = {
                'uid' : props.user.id,
                'name' : name,
                'email' : email,
                'phone' : phone,
                'prwi' : prwi,
                'mesimeri' : mesimeri
            }
            const header = new Headers(authHeader());
            header.set('Accept', 'application/json');
            header.set('Content-Type', 'application/json')
            header.set("Connection", "close");
            fetch(process.env.REACT_APP_SERVER + '/api/admin/update_student', {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((response)=>{
                        if (response.status === 200){
                            console.log(response)
                            setAltered(false)
                        }
                    return response.json();
                    }
                );

        }
        else{
            //TODO: Create user on Firebase
            const data = {
                'name' : name,
                'email' : email,
                'phone' : phone,
                'prwi' : prwi,
                'mesimeri' : mesimeri
            }
            const header = new Headers(authHeader());
            header.set('Accept', 'application/json');
            header.set('Content-Type', 'application/json');
            header.set("Connection", "close");
            fetch(process.env.REACT_APP_SERVER + '/api/admin/create_student', {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((resp)=>{
                    if (resp.status === 200){
                        alert('User Added');
                        props.reload[1](props.reload[0]+1);
                    }
                    }
                );
        }

        }




    const deleteHandler = (e) =>{
        e.preventDefault();
        confirmAlert({
            title: props.user.name,
            message: "Are you sure you want to delete him?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        //TODO: delete the user and the realtime database entry
                        if (props.user){
                            //TODO: POST data to be updated on Firebase
                            const data = {
                                'uid' : props.user.id
                            }
                            const header = new Headers(authHeader());
                            header.set('Accept', 'application/json');
                            header.set('Content-Type', 'application/json')
                            header.set("Connection", "close");
                            fetch(process.env.REACT_APP_SERVER + '/api/admin/delete_student', {
                                method: 'POST',
                                headers: header,
                                body: JSON.stringify(data)
                            })
                                .then((response)=>{
                                        if (response.status === 200){
                                            props.reload[1](props.reload[0]+1);
                                        }
                                });

                        }
                        console.log('deleted')
                    }
                },
                {
                    label: "No",
                    onClick: () => {

                    }
                }
            ]
        })
    };

    return(
        <>
            <Container>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                    <Form.Group className="mb-3" controlId="UserName">
                                        <Form.Label>Name:</Form.Label>
                                        <Form.Control type="text" value={name} onChange={(e)=>{
                                            setName(e.target.value);
                                            setAltered(true);
                                        }} required />
                                    </Form.Group>
                                    </Row>
                                    <Row>
                                    <Form.Group className="mb-3" controlId="userMail">
                                        <Form.Label>Email address:</Form.Label>
                                        <Form.Control type="email" value={email} onChange={(e)=>{
                                            setEmail(e.target.value);
                                            setAltered(true);
                                        }} required/>
                                    </Form.Group>
                                    </Row>
                                    <Row>
                                    <Form.Group className="mb-3" controlId="UserPhone">
                                        <Form.Label>Phone:</Form.Label>
                                        <Form.Control type="text" value={phone} onChange={(e)=>{
                                            setPhone(e.target.value);
                                            setAltered(true);
                                        }} required/>
                                    </Form.Group>
                                    </Row>
                                    <Row>
                                        <div className="col-6">
                                            <Form.Group className="mb-3" controlId="UserPrwi">
                                                <Form.Label>Prwi:</Form.Label>
                                                <Form.Control type="number" min={0} value={prwi} onChange={(e)=>{
                                                    setPrwi(e.target.value);
                                                    setAltered(true);
                                                }} required/>
                                            </Form.Group>
                                        </div>
                                        <div className="col-6">
                                            <Form.Group className="mb-3" controlId="UserMerimeri">
                                                <Form.Label>Mesimeri:</Form.Label>
                                                <Form.Control type="number" min={0} value={mesimeri} onChange={(e)=>{
                                                    setMesimeri(e.target.value);
                                                    setAltered(true);
                                                }} required/>
                                            </Form.Group>
                                        </div>
                                    </Row>
                                    {props.user ? <Button variant="danger" className="mx-3"
                                             onClick={deleteHandler}><FaTrash/>&nbsp;Delete</Button> : <></>}
                                    {altered ? <Button type="submit" className="mx-3">Save Changes</Button> : <></>}

                                </Form>
            </Container>
            </>
    );


}
export default StudentForm