import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useEffect, useState} from "react";
import React from "react";
import "./map.css";
import bus from "../icons/school-bus.svg";
import {Alert, Button, Container, Row} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import authHeader from './Authentication/authHeader';
import {FaPhone} from "react-icons/fa";




function MyFirstMap(props) {



    const {isLoaded} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const [broadcasting, setBroadcasting] = useState(false)
    const [position, setPosition] = useState({lat: parseFloat(process.env.REACT_APP_LATITUDE), lng: parseFloat(process.env.REACT_APP_LATITUDE)})
    const [error, setError] = useState()
    const [show, setShow] = useState(false);
    const [students, setStudents] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getStudents = async () => {
        const user = JSON.parse(localStorage.getItem('token'));
        const username = user.username;
        const header = new Headers(authHeader());
        const data = {
            // 'username' : username,
            'location' : props.location
        }
        header.set('Accept', 'application/json');
        header.set('Content-Type', 'application/json')
        header.set("Connection", "close");
        return fetch(process.env.REACT_APP_SERVER + '/api/func/get_current_students', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        });
    }
    const [location, setLocation] = useState(''+props.location);
    useEffect(()=>{
        setBroadcasting(false);
        setLocation(''+props.location)

        if(marker) {
            marker.setLabel('' + props.location)
        }
        getStudents().then((response)=>{
            if(response.status===200) {
                return (response.json())
            }
        }).then((data)=>{
            if(data) {
                setStudents([]);
                for (const user in data.users) {
                    setStudents(students => [...students, data.users[user]]);
                }
            }
        })
    },[props.location]);
    const [zoom_def,setZoom_def] = useState(17)
    useEffect(() => {
        if(!broadcasting){
            setPosition({
                lat: parseFloat(process.env.REACT_APP_LATITUDE),
                lng: parseFloat(process.env.REACT_APP_LONGITUDE)
            });
            console.log(position)
            const header = new Headers(authHeader());
            header.set('Accept', 'application/json');
            header.set('Content-Type', 'application/json');
            header.set("Connection", "close");
            fetch(process.env.REACT_APP_SERVER + "/api/func/post_default_loc", {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    'location':props.location,
                })
            }).then(function (response) {
                if (response.status !== 200) {
                    return response.json();
                }
                setError();
            }).then((data) => {
                if (data) {
                    setError(data.message);
                    setBroadcasting(false);
                }});
        }
        var options = {
            enableHighAccuracy: true,
            timeout: 500,
            maximumAge: 0,
        };
        const intervalPosition = setInterval(() => {
            // console.log(broadcasting)
            if (broadcasting) {

                map.setZoom(17)
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        setPosition({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        });
                        console.log(JSON.stringify({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }))
                        console.log(`More or less ${position.coords.accuracy} meters.`);
                        const header = new Headers(authHeader());
                        header.set('Accept', 'application/json');
                        header.set('Content-Type', 'application/json');
                        fetch(process.env.REACT_APP_SERVER + "/api/func/post_bus_loc", {
                            method: 'POST',
                            headers: header,
                            body: JSON.stringify({
                                'location':props.location,
                                'lat': position.coords.latitude,
                                'lng': position.coords.longitude
                            })
                        }).then(function (response) {
                            if (response.status !== 200) {
                                return response.json();
                            }
                            setError();
                        }).then((data) => {
                            if (data) {
                                setError(data.message);
                                setBroadcasting(false);
                            }

                        });
                        // you can use pos to get location
                    }, function (e) {
                        console.log(e);
                        // handle errors here if any
                    },options);
                } else {
                    // Browser doesn't support Geolocation
                    alert('You did not give Location Access!')
                }
            }
        }, 1000)
        return () => clearInterval(intervalPosition);

    }, [broadcasting])

    const clickHandler = () => {

        broadcasting === false ? setBroadcasting(true)
            //TODO ADD TRACKING FUNCTIONALITY
            //start tracking

            :

            //TODO ADD STOP TRACKING FUNCTIONALITY

            setBroadcasting(false)
    }
    const [map, setMap] = React.useState(null)
    const [marker, setMarker] = useState(null)

    return (
        <>
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <Container>
                    {error ? (<Row className="mx-5"><Alert variant="danger">{error}</Alert></Row>) : <></>}
                    <Row style={{"height": "65vh"}} className="shadow">
                        <GoogleMap
                            // set the state with the current instance of map.
                            onLoad={map => {
                                setMap(map)
                            }}
                            mapContainerClassName="map-container"
                            center={position}
                            zoom={zoom_def}
                            minZoom= {17}
                            maxZoom={17}
                            clickableIcons={false}
                            options={{ scrollwheel: false,fullscreenControl: false, zoomControl : false, streetViewControl: false, mapTypeControl:false}}
                        >
                            <MarkerF
                                onLoad={marker => {
                                    setMarker(marker)
                                }}
                                position={position}
                                // icon={bus}
                                label={location}
                            />
                        </GoogleMap>
                    </Row>
                    {/*{broadcasting ?*/}
                    {/*    <Row className="mt-5">*/}
                    {/*        <div className="col-6">*/}
                    {/*            Next: Child Name*/}
                    {/*        </div>*/}
                    {/*        <div className="col-6">*/}
                    {/*            <Button variant="success" className="mx-2"> OK </Button>*/}
                    {/*            <Button variant="light" className="mx-2 "> CALL </Button>*/}
                    {/*        </div>*/}
                    {/*    </Row>*/}
                    {/*    :*/}
                    {/*    <></>*/}
                    {/*}*/}

                    <Row className="mt-3 mb-1 px-3">
                        <div className={'col-6'}>
                            <Button  variant={broadcasting === false ? 'success' : 'danger'}
                                    onClick={clickHandler}>{broadcasting === false ? 'start' : 'stop'} </Button>
                        </div>
                        <div className={'col-6'}>
                            <>
                                <Button variant="light" onClick={handleShow}>
                                    Student List
                                </Button>

                                <Modal show={show} onHide={handleClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Students List</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {   students ? <ListGroup>
                                                {students.map((student)=>{
                                                    return(<ListGroup.Item>
                                                        <a href={'tel:'+ student.phone }>
                                                            <Row>
                                                        <div className={'col-10'}>
                                                            {student.name}
                                                        </div>
                                                        <div className={'col-2 text-end'}>
                                                            <FaPhone></FaPhone>
                                                        </div>
                                                            </Row>
                                                        </a>
                                                    </ListGroup.Item>)
                                                })}</ListGroup>
                                            :
                                            <Row>
                                                <div className={'col-12 text-center '}>
                                                    <Alert variant="warning">
                                                        There are no students with you or you don't have a location assigned!
                                                        <br/>
                                                        Please check with your admin!
                                                    </Alert>
                                                </div>
                                            </Row>
                                        }
                                    </Modal.Body>
                                </Modal>
                            </>
                        </div>
                    </Row>

                </Container>

            )}

        </>
    );
};

export default MyFirstMap;