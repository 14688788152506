import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FaTrash} from "react-icons/fa";
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import authHeader from "../Authentication/authHeader"; // Import css


function SingleUser(props) {

    const [name, setName] = useState("");
    const [prwi, setPrwi] = useState(0);
    const [mesimeri, setMesimeri] = useState(0);
    const [altered, setAltered] = useState(false);
    const [roleId, setRoleId] = useState(0);

    const getTimes = async () => {
        const header = new Headers(authHeader());
        const data = {
            'uid': props.user.id
        }
        header.set('Accept', 'application/json');
        header.set('Content-Type', 'application/json')
        header.set("Connection", "close");
        return fetch(process.env.REACT_APP_SERVER + '/api/admin/get_user_times', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        });
    }
    const updateUser = async () => {
        const header = new Headers(authHeader());
        const data = {
            'uid': props.user.id,
            'username': name,
            'roleId': roleId,
            'prwiId': prwi,
            'mesimeriId': mesimeri
        }
        header.set('Accept', 'application/json');
        header.set('Content-Type', 'application/json')
        header.set("Connection", "close");
        return fetch(process.env.REACT_APP_SERVER + '/api/admin/update_user', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        });
    }
    const deleteUser = async () => {
        const header = new Headers(authHeader());
        const data = {
            'uid': props.user.id
        }
        header.set('Accept', 'application/json');
        header.set('Content-Type', 'application/json')
        header.set("Connection", "close");
        return fetch(process.env.REACT_APP_SERVER + '/api/admin/delete_user', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        });
    }

    useEffect(() => {
        const user = props.user;
        if (user) {
            setName(user.username);
            setRoleId(user.roleId);
            getTimes().then((resp) => {
                return resp.json()
            }).then((data) => {
                data.map((time) => {
                    if (time.name == "prwi") {
                        setPrwi(time.locationId)
                    }
                    if (time.name == "mesimeri") {
                        setMesimeri(time.locationId)
                    }
                })
            })


        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateUser().then((resp) => {
            console.log(resp)
            setAltered(false)
        })


    };


    const deleteHandler = (e) => {
        e.preventDefault();
        if (JSON.parse(localStorage.getItem('token')).id === props.user.id) {
            alert('You cannot delete the currently logged in user!')
        } else {
            deleteUser().then((r) => {
                console.log(r)
            })
        }

    };

    return (
        <>
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group className="mb-3" controlId="UserName">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => {
                                setName(e.target.value);
                                setAltered(true);
                            }} required/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className={"mb-3"} controlId={"userRole"}>
                                <Form.Select value={roleId} onChange={(e) => {
                                    setRoleId(e.target.value);
                                    setAltered(true)
                                }} disabled={JSON.parse(localStorage.getItem('token')).id === props.user.id}>
                                    {/*1,bus driver
                                        2,teacher
                                        3,admin
                                        */}
                                    <option value={3} key={3}>Admin</option>
                                    <option value={1} key={1}>Driver</option>
                                    <option value={2} key={2}>Teacher</option>

                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col-6">
                            <Form.Group className="mb-3" controlId="UserPrwi">
                                <Form.Label>Prwi:</Form.Label>
                                <Form.Control type="number" min={0} value={prwi} onChange={(e) => {
                                    setPrwi(e.target.value);
                                    setAltered(true);
                                }} required/>
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="mb-3" controlId="UserMerimeri">
                                <Form.Label>Mesimeri:</Form.Label>
                                <Form.Control type="number" min={0} value={mesimeri} onChange={(e) => {
                                    setMesimeri(e.target.value);
                                    setAltered(true);
                                }} required/>
                            </Form.Group>
                        </div>
                    </Row>
                    {props.user ? <Button variant="danger" className="mx-3"
                                          onClick={deleteHandler}><FaTrash/>&nbsp;Delete</Button> : <></>}
                    {altered ? <Button type="submit" className="mx-3">Save Changes</Button> : <></>}

                </Form>
            </Container>
        </>
    );


}

export default SingleUser