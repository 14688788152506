import React, { useState } from "react";
import authHeader from '../Authentication/authHeader';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container, Row } from "react-bootstrap";
import BusMonitoring from "./BusMonitoring";
import Students from "./Students";
import {logOut} from "../Authentication/authenticationHandler";
import Personel from "./Personel";
import ScanStudents from "./ScanStudents";


///api/test/admin

async function isAdmin(setAdmin, setLoading) {
    const header = new Headers(authHeader());
          header.set('Accept', 'application/json');
          header.set('Content-Type', 'application/json');
    fetch(process.env.REACT_APP_SERVER + '/api/test/admin', {
      method: 'GET',
      headers: header,
        })
    .then((response) => {
        if (response.status !== 200){
            return response.json();
          }
          setAdmin(true);
          setLoading(false);
      }).then((data)=>{
        if (data){
          setAdmin(false)
          setLoading(false);
          logOut();
        }
    });
   }

function AdminBoard(){
    const [admin,setAdmin]= useState(false)
    const [loading, setLoading] = useState(true)
    const [key, setKey] = useState('students');

    isAdmin(setAdmin, setLoading);
    return(
        <>
        {
            loading ? <h1>Loading...</h1> : admin ?  (<>
            <Container className={"mb-3"}>
                <Row>
                    <div className="col-12">
                        <h1>Σελίδα Admin</h1>
                    </div>
                    <div className="col-12 tabular">
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        >
                        <Tab eventKey="students" title="Μαθητές">
                            {key==="students" ? <Students/> : <></>}
                        </Tab>
                        <Tab eventKey="personel" title="Προσωπικό">
                            {key==="personel" ? <Personel/> : <></>}
                            
                        </Tab>
                        <Tab eventKey="bus" title="Δρομολόγια">
                            {/* Tab content for Bus monitoring */}
                            {key==="bus" ?  <BusMonitoring/> : <></>}
                        </Tab>
                        <Tab eventKey="attendance" title="Κάρτες Εισόδου">
                            {/* Tab content for Bus monitoring */}
                            {key==="attendance" ?  <ScanStudents/> : <></>}
                        </Tab>
                        {/*<Tab eventKey="order" title="Order Students">*/}
                        {/*    /!* Tab content for Bus monitoring *!/*/}
                        {/*    {key==="order" ?  <StudentsOrder/> : <></>}*/}
                        {/*</Tab>*/}

                    </Tabs>
                    </div>
                </Row>
            </Container>
            
            
            
            
            </>) : <><h1>Admin</h1> <p>You don't have privilage, go <a href='/'>home</a></p></> 
        }
        
        </>
    )
    


}
export default AdminBoard