import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {Alert, Button, Col, Container, Row} from "react-bootstrap";
import {FaTrash} from "react-icons/fa";
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import authHeader from "../Authentication/authHeader"; // Import css


function AddUser(props) {

    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [roleId, setRoleId] = useState(3);
    const [error, setError] = useState()


    const addUser = async () => {
        const header = new Headers(authHeader());
        const data = {
            'username': name,
            'password' : password,
            'roles': [roleId]
        }
        header.set('Accept', 'application/json');
        header.set('Content-Type', 'application/json')
        header.set("Connection", "close");
        return fetch(process.env.REACT_APP_SERVER + '/api/admin/add_user', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        addUser().then((resp) => {
            console.log(resp)
            if (resp.status === 200){
                setError();
                alert('User Added');
                props.reload[1](props.reload[0]+1);
            }
            else{
                return(resp.json())
            }
        })
            .then((data)=>{
                if(data){
                    setError(data.message)
                }
            })

    };

    return (
        <>
            <Container>
                {error ? (<Row className="mx-5"><Alert variant="danger">{error}</Alert></Row> ) : <></>}
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Form.Group className="mb-3" controlId="UserName">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" value={name} onChange={(e) => {
                                setName(e.target.value);
                            }} required/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Password">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control type="text" value={password} onChange={(e) => {
                                setPassword(e.target.value);
                            }} required/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className={"mb-3"} controlId={"userRole"}>
                                <Form.Select value={roleId} onChange={(e) => {
                                    setRoleId(e.target.value);
                                }}>
                                    {/*1,bus driver
                                        2,teacher
                                        3,admin
                                        */}
                                    <option value={3} key={3}>Admin</option>
                                    <option value={1} key={1}>Driver</option>
                                    <option value={2} key={2}>Teacher</option>

                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button type="submit" className="mx-3">Add User</Button>

                </Form>
            </Container>
        </>
    );


}

export default AddUser