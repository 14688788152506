import React, { useEffect, useState } from "react";
import {Alert, Container, Row} from "react-bootstrap";
import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import bus from "../../icons/school-bus.svg";
import {fetchEventSource} from "@microsoft/fetch-event-source";

///api/test/admin



function MapView(props){
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ,
    });
    const [position, setPosition] = useState({lat: 0 , lng: 0})
    const [error, setError] = useState()
    useEffect(() => {
        if(marker) {
            marker.setLabel('' + props.location)
        }
        console.log("opening" + props.location)
        const user = JSON.parse(localStorage.getItem('token'));
        const controller = new AbortController();

        const fetchData = async () => {
            await fetchEventSource(process.env.REACT_APP_SERVER + '/api/stream/admin/get_the_bus_location', {
                method: "POST",
                headers:  { 'x-access-token': user.accessToken,
                    "Content-type" : "application/json",
                    'Accept' : 'text/event-stream'
                },
                body: JSON.stringify({location: 'location'+props.location}),
                signal: controller.signal,
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        console.log("Connection made ", res);
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.log("Client side error ", res);
                    }
                },
                onmessage(event) {
                    console.log(event.data);
                    let data = JSON.parse(event.data);
                    setPosition({
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude)
                    });
                },
                onclose() {
                    console.log("Connection closed by the server");
                },
                onerror(err) {
                    console.log("There was an error from server", err);
                    setError(err);
                },
            });
        };
        fetchData();
        return () => controller.abort();
    }, [props.location]);
    const [marker, setMarker] = useState(null)

    return(
        <>
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <Container>
                    {error ? (<Row className="mx-5"><Alert variant="danger">{error}</Alert></Row> ) : <></>}
                    <Row style={{"height":"50vh"}} className="shadow mt-3">
                        <GoogleMap
                            mapContainerClassName="map-container rounded-3"
                            center={position}
                            zoom={15}
                            options={{fullscreenControl: false, zoomControl : false, streetViewControl: false, mapTypeControl:false}}
                        >
                            <MarkerF
                                onLoad={marker => {
                                    setMarker(marker)
                                }}
                                position={position}
                                label = {''+props.location}
                            />
                        </GoogleMap>
                    </Row>

                </Container>

            )}

        </>
    )



}
export default MapView