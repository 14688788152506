import React, {useRef} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './components/Login';
import './App.css';
import Menu from './components/Menu';
import UserBar from './components/UserBar';
import useToken from './components/Authentication/useToken';
import authHeader from './components/Authentication/authHeader';
import {logOut} from './components/Authentication/authenticationHandler';
import AdminBoard from './components/admin/Admin';
import DriverView from "./components/DriverView";



async function validateUser(token) {
    const header = new Headers(authHeader());
    header.set('Accept', 'application/json');
    header.set('Content-Type', 'application/json');
    fetch(process.env.REACT_APP_SERVER + '/api/test/role', {
        method: 'GET',
        headers: header,
    })
        .then((response) => {
            // The response is a Response instance.
            // You parse the data into a useable format using `.json()`
            let res = response;
            if (res.status === 401) {
                logOut();
            }
            return res.json();
        }).then((data) => {
    });
}



function App() {

    if ('wakeLock' in navigator) {
        let lock;
        document.addEventListener('touchstart', enableWakeLock);
        async function enableWakeLock() {
            if (lock) {
                // console.log(`Release screen button pressed.`);
                // document.removeEventListener('touchstart', enableWakeLock, false);
                return;
            }

            try {
                console.log(`Acquire screen button pressed.`);
                lock = await navigator.wakeLock.request('screen');
                console.log(lock );

                lock.addEventListener('release', () => {
                    console.log(`screen lock released.`);
                    lock = null;
                });
            } catch (e) {
                console.log(`Caught ${e.name} acquiring screen lock: ${e.message}`);
            }
        }
    } else {
        console.log ('Not supported');
    }

    // require('dotenv').config();
    const {token, setToken} = useToken();
    const setUser = (t) => {
        setToken(t)
        window.location.reload(false);
    }

    if (!token) {
        return <Login setUser={setUser}/>
    }
    validateUser();

    return (
        <BrowserRouter>
            <div className="App">
                <Menu/>
                <UserBar/>

                <Routes>
                    {/*<Route path="/" element={<StudentsList/>}/>*/}
                    <Route path="/" element={<DriverView/>}/>
                    <Route path="/admin" element={<AdminBoard/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
