import React, { useEffect, useState } from "react";
import authHeader from './Authentication/authHeader';
import Form from 'react-bootstrap/Form';
import MyFirstMap from "./MyFirstMap";
import {Container, Row} from "react-bootstrap";

///api/test/admin

const getLocations = async  () => {
    const header = new Headers(authHeader());
    header.set('Accept', 'application/json');
    return fetch(process.env.REACT_APP_SERVER + '/api/func/get_locs', {
        method: 'GET',
        headers: header,
    });
}

//
// async function getCurrentLocation() {
//  const header = new Headers(authHeader());
//        header.set('Accept', 'application/json');
//        header.set('Content-Type', 'application/json');
//  fetch('http://localhost:9000/api/func/get_current_location', {
//    method: 'POST',
//    headers: header,
//    body : JSON.stringify({'location':'location1'})
//      });
// }

function BusMonitoring(){
    const [locations,setLocations]= useState([])
    const [loading, setLoading] = useState(true)
    useEffect(()=>{

        getLocations().then((response) => {
            console.log(response);
            return response.json();

        }).then((data)=>{
            if (data){
                console.log(data);
                setLocations(data.locations);
                setLoading(false);
            }
        });

    },[])

    const[location,setLocation] = useState(0)
    const[prevLoc, setPrevLoc] = useState(0)
    const chooseMap = (e) =>{
        setPrevLoc(location)
        setLocation(parseInt(e.target.value))

    }
    const delay = ms => new Promise(res => setTimeout(res, ms));

    useEffect(() => {
        if (prevLoc !== 0) {
            delay(2000).then(()=>{
                const header = new Headers(authHeader());
                header.set('Accept', 'application/json');
                header.set('Content-Type', 'application/json');
                header.set("Connection", "close");
                fetch(process.env.REACT_APP_SERVER + "/api/func/post_default_loc", {
                    method: 'POST',
                    headers: header,
                    body: JSON.stringify({
                        'location': prevLoc,
                    })
                }).then(function (response) {
                    if (response.status !== 200) {
                        return response.json();
                    }
                }).then((data) => {
                    if (data) {
                    }
                });
            })

        }
    }, [prevLoc])



    return(
        <Container>
            <Row>
            {
                loading ? <h1>Loading...</h1> : <div>
                    <Form.Select className={'mb-3'} type="dark" aria-label="Default select example" onChange={(e)=>{chooseMap(e)}}>
                        <option key={0} value={0}>Δρομολόγια...</option>
                        {locations.map((location)=>{
                            return(<option key={location.id} value={location.id}>Δρομολόγιο {location.id}</option>)
                        })}
                    </Form.Select>
                    {
                        location === 0 ? <div>Παρακαλώ επέλεξε ένα δρομολόγιο</div> : <MyFirstMap location={location}/>
                    }

                </div>
            }
            </Row>
        </Container>
    )



}
export default BusMonitoring